import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/Seo";

const About = () => (
  <Layout>
    <SEO description="About" lang="en" title="About" />
    <article className="pb5">
      <header className="tc ph3 mb5 ph4-ns">
        <h1 className="f2 mt0 lh-title">About me</h1>
      </header>
      <div className="ph3 ph4-m ph5-l">
        <p>
          Hi, I&apos;m Vu. I&apos;m a software engineer based in Saigon, Vietnam. I write about
          software related and other stupid stuff.
        </p>
      </div>
      <div className="ph3 ph4-m ph5-l">
        <p>
          Xin chào, mình là Vũ. Mình là một kĩ sư phần mềm hiện đang sống và làm việc tại Sài Gòn.
          Mình viết về phần mềm và những thứ linh tinh khác.
        </p>
      </div>
    </article>
  </Layout>
);

export default About;
